<template>
  <v-app>
    <div
      class="d-flex flex-row client-profile"
      v-if="profile && profileDataProject"
    >
      <div
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
        id="kt_profile_aside"
      >
        <div class="card card-custom card-stretch">
          <div class="card-body pt-4">
            <div class="d-flex align-items-center">
              <div>
                <span class="font-weight-bolder font-size-h6 text-dark-75">{{
                  profile.first_name + " " + profile.last_name || "-"
                }}</span>
                <div
                  class="text-muted font-size-h4 text-dark-75"
                  v-if="profile.client_project && false"
                >
                  {{ profile.client_project.name }}
                </div>
                <b-badge
                  class="mr-1 mt-1 d-block"
                  variant="info"
                  v-if="getCampaign"
                  >{{ getCampaign }}</b-badge
                >
              </div>
            </div>
            <div
              class="d-flex mt-1"
              v-if="
                profileDataProject.client &&
                profileDataProject.client.projects_list.length > 1
              "
            >
              <v-select
                :items="projectsList"
                item-text="project_name"
                item-value="project_id"
                dense
                hide-details
                outlined
                v-model="selectedProject"
                @change="selectProject"
              >
                <template v-slot:prepend-item>
                  <div class="px-5 mb-4">
                    <v-text-field
                      v-model.trim="projectsListSearch"
                      placeholder="Search"
                      hide-details
                      class="mt-0 pt-0 text-h6 w-100"
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </div>
            <!--end::User-->
            <!--begin::Contact-->
            <div class="py-9">
              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <span class="text-dark-50 justify-data">{{
                  profile.email || "-"
                }}</span>
              </div>
              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Mobile No:</span>
                <span class="text-dark-50 justify-data">{{
                  profile.phone || "-"
                }}</span>
              </div>
              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Meeting Date:</span>
                <span
                  class="text-dark-50 justify-data"
                  v-if="
                    profileDataProject && profileDataProject.meeting_start_time
                  "
                  >{{
                    submissionDate(profileDataProject.meeting_start_time) +
                    " GMT"
                  }}</span
                >
                <span class="text-dark-50 justify-data" v-else>{{ "-" }}</span>
              </div>
              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Meeting Link:</span>
                <span class="text-dark-50 justify-data">
                  <a
                    v-if="profileDataProject && profileDataProject.meeting_link"
                    :href="profileDataProject.meeting_link"
                    target="_blank"
                    >Link</a
                  >
                  <span class="text-dark-50" v-else>-</span>
                </span>
              </div>
              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Squad Request Date:</span>
                <span
                  class="text-dark-50 justify-data"
                  v-if="profileDataProject.submission_date"
                  >{{
                    submissionDate(profileDataProject.submission_date)
                  }}</span
                >
                <span class="text-dark-50 justify-data" v-else>{{ "-" }}</span>
              </div>

              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Deal Owner:</span>
                <span
                  class="text-dark-50 justify-data"
                  v-if="
                    profileDataProject && profileDataProject.deal_owner_email
                  "
                  >{{ profileDataProject.deal_owner_email }}</span
                >
                <span class="text-dark-50 justify-data" v-else>{{ "-" }}</span>
              </div>

              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Account Manager:</span>
                <span
                  class="text-dark-50 justify-data"
                  v-if="
                    profileDataProject &&
                    profileDataProject.account_manager_email
                  "
                  >{{ profileDataProject.account_manager_email }}</span
                >
                <span class="text-dark-50 justify-data" v-else>{{ "-" }}</span>
              </div>
              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Rec. Consultant:</span>
                <span
                  class="text-dark-50 justify-data"
                  v-if="
                    profileDataProject &&
                    profileDataProject.rec_consultant_email
                  "
                  >{{ profileDataProject.rec_consultant_email }}</span
                >
                <span class="text-dark-50 justify-data" v-else>{{ "-" }}</span>
              </div>

              <div class="d-flex align-items-center mb-2">
                <span class="font-weight-bold mr-2">Clickup URL:</span>
                <a
                  :href="profileDataProject.clickup_task_url"
                  v-if="
                    profileDataProject && profileDataProject.clickup_task_url
                  "
                  target="_blank"
                >
                  <img
                    class="d-flex align-items-start"
                    style="height: 2.5rem; width: 2.5rem"
                    src="@/assets/images/clickup.png"
                    alt="clickup"
                  />
                </a>
                <span class="text-dark-50 justify-data" v-else>{{ "-" }}</span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <span class="font-weight-bold mr-2">Renewal Type:</span>
                <div class="d-flex align-items-center">
                  <v-switch
                    style="height: 30px"
                    v-model="profileDataProject.renewal_type"
                    @change="changeRenewalType($event)"
                    :value="'auto'"
                    :label="`${
                      profileDataProject.renewal_type == 'auto'
                        ? 'Auto'
                        : 'Manual'
                    }`"
                    inset
                    :ripple="false"
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex align-items-center mb-2"
                v-if="
                  profileDataProject &&
                  profileDataProject.client?.hasOwnProperty('online_payments')
                "
              >
                <span class="font-weight-bold mr-2">Pay through Noon:</span>
                <div class="d-flex align-items-center">
                  <v-switch
                    style="height: 30px"
                    v-model="profileDataProject.client.online_payments"
                    @change="changeOnlinePayment($event)"
                    inset
                    :ripple="false"
                  ></v-switch>
                </div>
              </div>

              <div
                class="d-flex align-items-center mb-2"
                v-if="
                  profileDataProject &&
                  profileDataProject.hasOwnProperty('interview_allowed')
                "
              >
                <span class="font-weight-bold mr-2 w-50"
                  >Request an interview with Matched Talents:</span
                >
                <div class="d-flex align-items-center">
                  <v-switch
                    style="height: 30px"
                    v-model="profileDataProject.interview_allowed"
                    @change="changeRequestInterview($event)"
                    inset
                    :ripple="false"
                  ></v-switch>
                </div>
              </div>
              <div
                class="d-flex align-items-center mb-2"
                v-if="profile.signup_sources && profile.signup_sources.length"
              >
                <span class="font-weight-bold mr-2 w-50">Lead source:</span>
                <div class="d-flex align-items-center">
                  <span
                    v-for="(source, index) in profile.signup_sources"
                    :key="source.id"
                    class="text-dark-50 justify-data"
                  >
                    {{ source.name || "-" }}
                    <span v-if="index < profile.signup_sources.length - 1">
                      , &nbsp;
                    </span>
                  </span>
                </div>
              </div>
              <div
                class="d-flex align-items-center mb-2"
                v-if="profile.utm_source && profile.utm_source.medium"
              >
                <span class="font-weight-bold mr-2 w-50">Lead Medium:</span>
                <div class="d-flex align-items-center">
                  <span class="text-dark-50 justify-data">
                    {{ profile.utm_source.medium || "-" }}
                  </span>
                </div>
              </div>
              <div
                class="d-flex align-items-center mb-2"
                v-if="profile.utm_source && profile.utm_source.campaign"
              >
                <span class="font-weight-bold mr-2 w-50">Lead Campaign:</span>
                <div class="d-flex align-items-center">
                  <span class="text-dark-50 justify-data">
                    {{ profile.utm_source.campaign || "-" }}
                  </span>
                </div>
              </div>
            </div>
            <!--end::Contact-->
            <!--begin::Nav-->
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
              role="tablist"
            >
              <div class="navi-item mb-2">
                <a
                  ref="clientRequest"
                  class="navi-link py-4 active"
                  @click="setActiveTab"
                  style="cursor: pointer"
                  data-tab="1"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon">
                    <span class="svg-icon">
                      <i class="menu-icon far fa-user"></i>
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Client Request</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  ref="Stakeholders"
                  class="navi-link py-4"
                  @click="
                    (e) => {
                      profile.client_project && profile.projects_list.length
                        ? setActiveTab(e)
                        : false;
                    }
                  "
                  style="cursor: pointer"
                  :data-tab="
                    profile.client_project && profile.projects_list.length
                      ? 0
                      : null
                  "
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                  v-b-tooltip.hover.right="
                    profile.client_project && profile.projects_list.length
                      ? ''
                      : 'Add project details to be able to add stakeholders'
                  "
                >
                  <span class="navi-icon">
                    <span class="svg-icon">
                      <i class="menu-icon flaticon-mail"></i>
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Stakeholders</span>
                </a>
              </div>

              <div class="navi-item mb-2">
                <a
                  ref="nominations"
                  class="navi-link py-4"
                  @click="
                    (e) => {
                      profile.client_project && profile.projects_list.length
                        ? setActiveTab(e)
                        : false;
                    }
                  "
                  style="cursor: pointer"
                  :data-tab="
                    profile.client_project && profile.projects_list.length
                      ? 4
                      : null
                  "
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                  v-b-tooltip.hover.right="
                    profile.client_project && profile.projects_list.length
                      ? ''
                      : 'Add project details to be able to see nominations'
                  "
                >
                  <span class="navi-icon">
                    <span class="svg-icon">
                      <i class="menu-icon flaticon-layers"></i>
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Nominations</span>
                </a>
              </div>

              <div class="navi-item mb-2">
                <a
                  ref="selectedTalents"
                  class="navi-link py-4"
                  @click="
                    (e) => {
                      profile.client_project && profile.projects_list.length
                        ? setActiveTab(e)
                        : false;
                    }
                  "
                  style="cursor: pointer"
                  :data-tab="
                    profile.client_project && profile.projects_list.length
                      ? 5
                      : null
                  "
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                  v-b-tooltip.hover.right="
                    profile.client_project && profile.projects_list.length
                      ? ''
                      : 'Add project details to be able to see selected talents'
                  "
                >
                  <span class="navi-icon">
                    <span class="svg-icon">
                      <i class="menu-icon flaticon-squares-1"></i>
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Selected Talents</span>
                </a>
              </div>

              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="
                    (e) => {
                      $route.params.project_id
                        ? profileDataProject &&
                          [
                            ...profileDataProject.technologies,
                            ...profileDataProject.others_technologies,
                          ].length &&
                          profileDataProject.hiring_type
                          ? setActiveTab(e)
                          : false
                        : profile.client_project &&
                          [
                            ...profile.client_project.technologies,
                            ...profile.client_project.others_technologies,
                          ].length &&
                          profile.client_project.hiring_type
                        ? setActiveTab(e)
                        : false;
                    }
                  "
                  style="cursor: pointer"
                  :data-tab="
                    $route.params.project_id
                      ? profileDataProject &&
                        [
                          ...profileDataProject.technologies,
                          ...profileDataProject.others_technologies,
                        ].length &&
                        profileDataProject.hiring_type
                        ? 2
                        : null
                      : profile.client_project &&
                        [
                          ...profile.client_project.technologies,
                          ...profile.client_project.others_technologies,
                        ].length &&
                        profile.client_project.hiring_type
                      ? 2
                      : null
                  "
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                  v-b-tooltip.hover.right="
                    $route.params.project_id
                      ? profileDataProject &&
                        [
                          ...profileDataProject.technologies,
                          ...profileDataProject.others_technologies,
                        ].length &&
                        profileDataProject.hiring_type
                        ? ''
                        : 'Please select technologies & utilizations to be able to notify talents'
                      : profile.client_project &&
                        [
                          ...profile.client_project.technologies,
                          ...profile.client_project.others_technologies,
                        ].length > 0 &&
                        profile.client_project.hiring_type
                      ? ''
                      : 'Please select technologies & utilizations to be able to notify talents'
                  "
                >
                  <span class="navi-icon">
                    <span class="svg-icon">
                      <i class="menu-icon flaticon-email"></i>
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Notify Talent</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="
                    (e) => {
                      $route.params.project_id
                        ? profileDataProject &&
                          [
                            ...profileDataProject.technologies,
                            ...profileDataProject.others_technologies,
                          ].length &&
                          profileDataProject.hiring_type
                          ? setActiveTab(e)
                          : false
                        : profile.client_project &&
                          [
                            ...profile.client_project.technologies,
                            ...profile.client_project.others_technologies,
                          ].length &&
                          profile.client_project.hiring_type
                        ? setActiveTab(e)
                        : false;
                    }
                  "
                  :data-tab="
                    $route.params.project_id
                      ? profileDataProject &&
                        [
                          ...profileDataProject.technologies,
                          ...profileDataProject.others_technologies,
                        ].length &&
                        profileDataProject.hiring_type
                        ? 3
                        : null
                      : profile.client_project &&
                        [
                          ...profile.client_project.technologies,
                          ...profile.client_project.others_technologies,
                        ].length &&
                        profile.client_project.hiring_type
                      ? 3
                      : null
                  "
                  v-b-tooltip.hover.right="
                    $route.params.project_id
                      ? profileDataProject &&
                        [
                          ...profileDataProject.technologies,
                          ...profileDataProject.others_technologies,
                        ].length &&
                        profileDataProject.hiring_type
                        ? ''
                        : 'Please select technologies & utilizations to be able to notify talents'
                      : profile.client_project &&
                        [
                          ...profile.client_project.technologies,
                          ...profile.client_project.others_technologies,
                        ].length > 0 &&
                        profile.client_project.hiring_type
                      ? ''
                      : 'Please select technologies & utilizations to be able to notify talents'
                  "
                  style="cursor: pointer"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon">
                    <span class="svg-icon">
                      <i class="menu-icon fa fa-users"></i>
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Interested List</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin::Content-->
      <div class="flex-row-fluid ml-lg-8">
        <b-tabs class="hide-tabs">
          <b-tab :active="tabIndex == 0">
            <Stakeholders
              v-if="tabIndex == 0"
              :profile="profile"
              @update="
                $route.params.project_id
                  ? getProfileDataProjectWithOutLoading(
                      $route.params.project_id
                    )
                  : getProfileDataWithOutLoading($route.params.id)
              "
            ></Stakeholders>
          </b-tab>
          <b-tab :active="tabIndex == 1">
            <ClientBasicInfo
              v-if="tabIndex == 1"
              :profile="profile"
              ref="ClientBasicInfo"
              @update="
                $route.params.project_id
                  ? getProfileDataProjectWithOutLoading(
                      $route.params.project_id
                    )
                  : redirectToProjectProfile()
              "
            ></ClientBasicInfo>
          </b-tab>
          <b-tab :active="tabIndex == 4">
            <nominations v-if="tabIndex == 4" />
          </b-tab>
          <b-tab :active="tabIndex == 2">
            <NotifyTalants
              v-if="tabIndex == 2"
              :profile="profile"
              @backToProfile="$refs.clientRequest.click()"
            ></NotifyTalants>
          </b-tab>
          <b-tab :active="tabIndex == 3">
            <InterestedList
              :profile="profile"
              v-if="tabIndex == 3"
            ></InterestedList>
          </b-tab>
          <b-tab :active="tabIndex == 5">
            <selected-talents
              v-if="tabIndex == 5"
              :selectedProject="selectedProject"
            />
          </b-tab>
        </b-tabs>
      </div>

      <!--end::Content-->
    </div>
  </v-app>
</template>

<script>
// import dateFormat from "dateformat";
import dayjs from "dayjs";
// import { format } from "date-fns";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ClientBasicInfo from "@/components/client-profile/ClientBasicInfo.vue";
import NotifyTalants from "@/components/client-profile/NotifyTalants.vue";
import InterestedList from "@/components/client-profile/InterestedList.vue";
import Stakeholders from "@/components/client-profile/Stakeholders";
import selectedTalents from "@/components/client-profile/selectedTalents";
import nominations from "@/components/client-profile/nominations.vue";

export default {
  name: "ClientProfile",

  components: {
    ClientBasicInfo,
    NotifyTalants,
    InterestedList,
    Stakeholders,
    selectedTalents,
    nominations,
  },
  data() {
    return {
      tabIndex: 1,
      EditForm: false,
      profileId: null,
      projectsListSearch: null,
      selectedProject: null,
    };
  },
  created() {
    this.getMarketingCampaignList({ type: 2 });
    this.handleGettingProfileData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Client Profile" },
    ]);
  },
  computed: {
    ...mapGetters({
      profile: "clients/getProfileData",
      profileDataProject: "clients/getProfileDataProject",
      currentUserPersonalInfo: "currentUserPersonalInfo",
      marketingCampaignList: "shared/getMarketingCampaignList",
    }),
    currentTab() {
      return this.$route.params.tab;
    },
    projectsList() {
      return this.profileDataProject.client.projects_list.map((project) => ({
        ...project,
        project_name: project.project_name
          ? project.project_name
          : "Unnamed squad",
      }));
    },
    getCampaign() {
      return this.marketingCampaignList.find(
        (element) => element.id === this.profile.marketing_campaign
      )?.name;
    },
    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
  },
  methods: {
    ...mapActions({
      getProfileData: "clients/getProfileData",
      getProfileDataProject: "clients/getProfileDataProject",
      getProfileDataWithOutLoading: "clients/getProfileDataWithOutLoading",
      getProfileDataProjectWithOutLoading:
        "clients/getProfileDataProjectWithOutLoading",
      updateOnlinePayments: "clients/updateOnlinePayments",
      updateRenewalType: "clients/updateRenewalType",
      getMarketingCampaignList: "shared/getMarketingCampaignList",
      updateRequestInterview: "clients/updateRequestInterview",
    }),
    changeTab() {
      if (!this.profileDataProject) return;

      const tabMap = {
        "client-request": 1,
        stakeholders: 0,
        "notify-talents": 2,
        "interested-list": 3,
        nominations: 4,
        "selected-talents": 5,
      };

      const targetTab = tabMap[this.currentTab];
      const targetElement = document.querySelector(`[data-tab='${targetTab}']`);

      if (targetElement) {
        targetElement.click();
      } else {
        document.querySelector("[data-tab='1']")?.click();
        if (this.currentTab == "client-request") return;
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${this.$route.params.project_id}/client-request`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/client-request`
            );
      }
    },
    redirectToProjectProfile() {
      this.getProfileDataWithOutLoading(this.$route.params.id).then((res) => {
        let Project_id = res.projects_list[0].project_id;
        this.getProfileDataProjectWithOutLoading(Project_id);
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${Project_id}/${this.currentTab}`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/${this.currentTab}`
            );
      });
    },
    selectProject() {
      if (this.tabIndex == 1) this.$refs.ClientBasicInfo.editForm = false;

      this.projectsListSearch = "";
      this.getProfileDataProjectWithOutLoading(this.selectedProject);
      this.$route.params.project_id
        ? this.$router.push(
            `/dashboard/client/${this.$route.params.id}/project/${this.selectedProject}/${this.currentTab}`
          )
        : this.$router.push(
            `/dashboard/client/${this.$route.params.id}/${this.currentTab}`
          );
    },
    handleGettingProfileData() {
      this.profileId = this.$route.params.id;
      this.getProfileData(this.profileId);

      if (this.$route.params.project_id) {
        this.selectedProject = Number(this.$route.params.project_id);
        this.getProfileDataProject(this.$route.params.project_id).finally(
          () => {
            setTimeout(() => {
              this.changeTab();
            }, 1000);
          }
        );
      }
    },
    submissionDate(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm a");
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      if (this.tabIndex == 1 && this.currentTab != "client-request") {
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${this.$route.params.project_id}/client-request`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/client-request`
            );
      } else if (this.tabIndex == 2 && this.currentTab != "notify-talents") {
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${this.$route.params.project_id}/notify-talents`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/notify-talents`
            );
      } else if (this.tabIndex == 3 && this.currentTab != "interested-list") {
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${this.$route.params.project_id}/interested-list`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/interested-list`
            );
      } else if (this.tabIndex == 0 && this.currentTab != "stakeholders") {
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${this.$route.params.project_id}/stakeholders`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/stakeholders`
            );
      } else if (this.tabIndex == 5 && this.currentTab != "selected-talents") {
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${this.$route.params.project_id}/selected-talents`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/selected-talents`
            );
      } else if (this.tabIndex == 4 && this.currentTab != "nominations") {
        this.$route.params.project_id
          ? this.$router.push(
              `/dashboard/client/${this.$route.params.id}/project/${this.$route.params.project_id}/nominations`
            )
          : this.$router.push(
              `/dashboard/client/${this.$route.params.id}/nominations`
            );
      }

      // set current active tab
      target.classList.add("active");
    },
    changeOnlinePayment(event) {
      const Payload = {
        client_id: this.$route.params.id,
        online_payments: event,
      };
      this.updateOnlinePayments(Payload);
    },
    changeRenewalType(event) {
      const Payload = {
        project_id: this.$route.params.project_id,
        renewalType: event == "auto" ? "auto" : "manual",
      };
      Swal.fire({
        title: "Are you sure",
        html:
          event == "auto"
            ? `You are about to change the squad's renewal type to Auto. This will update all remote, part-time, and full-time contracts? ${
                this.profileDataProject.counters
                  .un_changeable_manual_renewable_contracts > 0
                  ? "<br/> <br/>" +
                    ` <stronge  class='font-weight-bolder'> ${this.profileDataProject.counters.un_changeable_manual_renewable_contracts} contracts are ongoing in the renewal cycle and are not affected by this change. </stronge> `
                  : ""
              }`
            : `You are about to change the squad's renewal type to Manual. This will update all contracts? ${
                this.profileDataProject.counters
                  .un_changeable_auto_renewable_contracts > 0
                  ? "<br/> <br/>" +
                    `<stronge class='font-weight-bolder'>  ${this.profileDataProject.counters.un_changeable_auto_renewable_contracts} contracts are ongoing in the renewal cycle and are not affected by this change. </stronge> `
                  : ""
              } `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          if (event == "auto") {
            this.updateRenewalType(Payload).catch(() => {
              this.profileDataProject.renewal_type = "manual";
            });
          } else {
            this.updateRenewalType(Payload).catch(() => {
              this.profileDataProject.renewal_type = "auto";
            });
          }
        } else {
          console.log("!isConfirmed");
          this.profileDataProject.renewal_type =
            event == "auto" ? "manual" : "auto";
        }
      });
    },
    changeRequestInterview(event) {
      const Payload = {
        project_id: this.$route.params.project_id,
        interview_allowed: event,
      };
      this.updateRequestInterview(Payload)
        .then(() => {})
        .catch(() => {
          this.profileDataProject.interview_allowed = !event;
        });
    },
  },
};
</script>

<style>
.hide-tabs > div:not(.tab-content) {
  display: none;
}

.font-size-h6 {
  font-size: 1.5rem !important;
}

.navi .navi-item .navi-link.active .navi-text {
  color: #6f56c5 !important;
}

.navi .navi-item .navi-link.active .navi-icon i {
  color: #6f56c5 !important;
}
.navi .navi-item .navi-link:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #6f56c5 !important;
}

.navi .navi-item .navi-link:hover .navi-icon i {
  color: #6f56c5 !important;
}

.navi .navi-item .navi-link:hover .navi-text {
  color: #6f56c5 !important;
}

.client-profile .card.card-custom > .card-body {
  padding: 2rem 1rem;
}

.client-profile .w-xl-350px {
  width: 370px !important;
}

.client-profile .justify-data {
  word-break: break-all;
}
</style>
