<template>
  <!--begin::Card-->
  <div>
    <div class="d-flex flex-row">
      <!--begin::Content-->
      <div class="flex-row-fluid">
        <!--begin::Row-->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-custom gutter-b card-stretch">
              <div class="card-body card-stretch.gutter-b">
                <!--begin::Details-->

                <div class="row">
                  <div class="col-lg-8">
                    <div class="d-flex flex-wrap flex-sm-nowrap">
                      <!--begin: Pic-->
                      <div
                        class="flex-shrink-0 mr-7 mt-lg-0 mt-3 d-flex flex-column"
                      >
                        <div>
                          <div
                            class="image-input image-input-outline profile-avatar"
                            id="kt_profile_avatar"
                          >
                            <v-progress-circular
                              :rotate="-90"
                              :size="125"
                              :width="7"
                              color="#41dbb1"
                              :value="profile.completeProfile.rate"
                            >
                              <div
                                class="image-input-wrapper rounded-circle"
                                :style="{ backgroundImage: `url(${photo})` }"
                              ></div>
                              <label
                                class="btn btn-sm btn-icon btn-circle btn-info btn-hover-text-primary btn-shadow"
                                data-action="change"
                                data-toggle="tooltip"
                                data-original-title="Change avatar"
                                :class="{ disabled: isUploadingImage }"
                              >
                                <v-progress-circular
                                  :size="25"
                                  :width="2"
                                  color="white"
                                  indeterminate
                                  v-if="isUploadingImage"
                                ></v-progress-circular>
                                <i
                                  class="fa fa-pen icon-sm text-white"
                                  v-else
                                ></i>
                                <input
                                  type="file"
                                  name="profile_avatar"
                                  accept=".jpg, .jpeg, .png, .svg"
                                  @change="uploadImage($event)"
                                />
                                <input
                                  type="hidden"
                                  name="profile_avatar_remove"
                                />
                              </label>
                              <span
                                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="cancel"
                                data-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i
                                  class="ki ki-bold-close icon-xs text-muted"
                                ></i>
                              </span>
                            </v-progress-circular>
                          </div>
                        </div>

                        <div
                          class="d-flex align-items-start flex-fill float-right col col-xl-4 p-0 mt-6 mt-md-4"
                        >
                          <span
                            class="font-weight-regular text-dark-75 text-nowrap"
                            >Profile Strength:</span
                          >
                          <span class="font-weight-bolder text-dark"
                            >{{ profile.completeProfile.rate }}%</span
                          >
                        </div>
                      </div>
                      <!--end::Pic-->

                      <!--begin::Info-->
                      <div class="flex-grow-1">
                        <!--begin::Title-->
                        <div>
                          <div class="d-flex mr-sm-10 align-items-center">
                            <h2
                              class="text-dark-75 font-size-h5 font-weight-bold mr-3 mb-0 py-3"
                            >
                              {{ profile.basicInfo.full_name }}
                            </h2>
                            <!-- <span v-if="profile.basicInfo.qualified"
                ><i class="flaticon2-correct text-success font-size-h2 mr-3"></i
              ></span> -->
                            <img
                              v-if="profile.basicInfo.nationality"
                              :src="`https://flagcdn.com/w80/${profile.basicInfo.nationality.code.toLowerCase()}.png`"
                              alt="country image"
                              width="24"
                              height="24"
                              class="rounded-circle"
                              v-b-tooltip.hover.top="`Nationality`"
                            />
                            <img
                              v-else-if="profile.basicInfo.country"
                              :src="`https://flagcdn.com/w80/${profile.basicInfo.country.code.toLowerCase()}.png`"
                              alt="country image"
                              width="24"
                              height="24"
                              class="rounded-circle"
                              v-b-tooltip.hover.top="`Country of residence`"
                            />
                          </div>
                        </div>
                        <!--end::Title-->

                        <AddClientToTalentModal
                          :contracts="profile.contracts || []"
                          :activeClient="activeClient"
                        >
                          <template #default="{ on }">
                            <span ref="clients-modal" v-on="on"></span>
                          </template>
                        </AddClientToTalentModal>

                        <NominateTalentToClientModal
                          :contracts="profile.contracts || []"
                          :activeNominate="activeNominate"
                          :currentRole="profile.basicInfo.role"
                        >
                          <template #default="{ on }">
                            <span ref="nominate-modal" v-on="on"></span>
                          </template>
                        </NominateTalentToClientModal>

                        <InquiryTalentModal>
                          <template #default="{ on }">
                            <span ref="inquiry-modal" v-on="on"></span>
                          </template>
                        </InquiryTalentModal>

                        <!--begin::Content-->
                        <div
                          class="d-flex flex-wrap justify-content-between mt-1"
                        >
                          <div class="d-flex flex-column flex-grow-1">
                            <div class="d-flex flex-wrap mb-4">
                              <span
                                class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2"
                                ><i
                                  class="flaticon2-new-email mr-2 font-size-lg"
                                ></i
                                >{{ profile.basicInfo.email }}</span
                              >
                              <span
                                class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2"
                                v-if="profile.basicInfo.phone"
                                ><i
                                  class="flaticon2-phone mr-2 font-size-lg"
                                ></i
                                >{{ profile.basicInfo.country_code }}
                                {{ profile.basicInfo.phone }}</span
                              >

                              <b-badge
                                class="mr-1 d-flex align-items-center"
                                variant="info"
                                v-if="getCampaign"
                                >{{ getCampaign }}</b-badge
                              >

                              <b-badge
                                class="mr-1 d-flex align-items-center"
                                variant="info"
                                v-if="profile.basicInfo.nominations_count"
                                >{{
                                  `Nominated To ${
                                    profile.basicInfo.nominations_count
                                  } ${
                                    profile.basicInfo.nominations_count > 1
                                      ? "Clients"
                                      : "Client"
                                  } `
                                }}</b-badge
                              >

                              <b-badge
                                class="mr-1 d-flex align-items-center"
                                variant="dark"
                                v-if="profile.basicInfo.assigns_count"
                                >{{
                                  `Assigned To ${
                                    profile.basicInfo.assigns_count
                                  } ${
                                    profile.basicInfo.assigns_count > 1
                                      ? "Clients"
                                      : "Client"
                                  } `
                                }}</b-badge
                              >

                              <b-badge
                                v-if="profile.basicInfo.job_type"
                                class="mr-1 d-flex align-items-center"
                                :variant="
                                  jobTypeColor(profile.basicInfo.job_type)
                                "
                              >
                                {{
                                  getJobTitle(profile.basicInfo.job_type)
                                }}</b-badge
                              >
                              <a
                                :href="profile.clickup_task_url"
                                v-if="profile.clickup_task_url"
                                target="_blank"
                              >
                                <img
                                  class="d-flex align-items-start mx-2"
                                  style="height: 2.5rem; width: 2.5rem"
                                  src="@/assets/images/clickup.png"
                                  alt="clickup"
                                />
                              </a>
                            </div>
                            <div
                              class="d-flex flex-column flex-md-row align-items-lg-center justify-content-between mb-6"
                            >
                              <div
                                class="d-flex align-items-center flex-md-column flex-xl-row"
                              >
                                <span
                                  class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-md-auto mr-xl-5"
                                  v-if="profile.basicInfo.role"
                                >
                                  <i
                                    class="flaticon2-calendar-3 mr-2 font-size-lg"
                                  >
                                  </i>
                                  {{ profile.basicInfo.role.name }}
                                </span>
                                <!-- if role is full stack -->
                                <template
                                  v-if="
                                    profile.basicInfo.role &&
                                    profile.basicInfo.role.id == 3
                                  "
                                >
                                  <span
                                    v-if="profile.basicInfo.BE_lang"
                                    class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-md-auto mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i
                                    >{{ profile.basicInfo.BE_lang }} -
                                    {{ profile.basicInfo.experience_years }}

                                    {{
                                      profile.basicInfo.experience_years == 1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                  <span
                                    v-if="profile.basicInfo.FE_lang"
                                    class="text-dark-50 font-weight-bold mb-2 mr-md-auto mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i
                                    >{{ profile.basicInfo.FE_lang }} -
                                    {{
                                      profile.basicInfo.second_experience_years
                                    }}

                                    {{
                                      profile.basicInfo
                                        .second_experience_years == 1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                </template>
                                <!-- if role is frontend or backend -->
                                <template
                                  v-else-if="
                                    profile.basicInfo.BE_lang ||
                                    profile.basicInfo.FE_lang
                                  "
                                >
                                  <span
                                    v-if="
                                      profile.basicInfo.BE_lang ||
                                      profile.basicInfo.FE_lang
                                    "
                                    class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-md-auto mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i
                                    >{{
                                      profile.basicInfo.BE_lang ||
                                      profile.basicInfo.FE_lang
                                    }}
                                    -
                                    {{ profile.basicInfo.experience_years }}

                                    {{
                                      profile.basicInfo.experience_years == 1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                </template>
                                <!-- for other roles-->
                                <template
                                  v-else-if="profile.basicInfo.experience_years"
                                >
                                  <span
                                    class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-md-auto mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i>
                                    {{ profile.basicInfo.experience_years }}
                                    {{
                                      profile.basicInfo.experience_years == 1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                </template>

                                <span
                                  v-if="
                                    profile.basicInfo.regulation_countries
                                      .length
                                  "
                                  class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-md-auto mr-xl-5"
                                  ><i
                                    class="flaticon-globe mr-2 font-size-lg"
                                  ></i>

                                  <b-badge
                                    v-for="country in uniqueArray(
                                      profile.basicInfo.regulation_countries
                                    )"
                                    :key="country.id"
                                    class="mr-1"
                                    :variant="getRandomValueFromArray()"
                                    >{{ country.name }}</b-badge
                                  >
                                </span>
                              </div>
                            </div>

                            <div
                              class="d-flex flex-column flex-md-row align-items-lg-center justify-content-between mb-6"
                            >
                              <div class="d-flex align-items-center pr-md-8">
                                <template v-if="profile.accounts.length">
                                  <a
                                    :href="getUrl(account.url)"
                                    target="_blank"
                                    v-for="account in profile.accounts"
                                    :key="account.id"
                                    class="mr-4"
                                  >
                                    <img
                                      :src="
                                        require(`@/assets/images/icons/${
                                          ACCOUNTS_TYPES[account.type]
                                        }.svg`)
                                      "
                                      alt="account icon"
                                      width="20"
                                  /></a>
                                </template>

                                <span class="text-muted" v-else
                                  >No Business Accounts</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--end::Content-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Details-->
                    <div class="separator separator-solid"></div>
                    <!--begin::Item-->
                    <div class="d-flex align-items-center flex-wrap mt-8">
                      <!--begin::Item-->
                      <div
                        class="d-flex align-items-center flex-lg-fill mr-5 mb-2"
                      >
                        <span class="mr-4">
                          <i
                            class="flaticon-file display-2 text-muted font-weight-bold"
                          ></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                          <span class="font-weight-bolder font-size-md"
                            >CV
                            <span>
                              <a
                                v-if="profile.cv"
                                class="font-weight-bolder basic-info__view"
                                target="_blank"
                                :href="`${awsBaseUrl}${profile.cv.path}`"
                                :download="profile.cv.name"
                                >( View )</a
                              ></span
                            >
                          </span>

                          <router-link
                            v-if="
                              profile.completeProfile.rate <= 50 &&
                              profile.cv &&
                              profile.cv.parsed_cv &&
                              profile.cv.parsed_cv.data
                            "
                            :to="{
                              name: 'talent-parse-cv',
                              params: { id: `${profileId}` },
                            }"
                            class="font-weight-bolder basic-info__view"
                            >Parse CV</router-link
                          >
                          <span
                            v-else
                            class="font-weight-bolder text-muted cursor-pointer"
                            v-b-tooltip
                            :title="
                              !profile.basicInfo.role
                                ? `Please Add the Role First`
                                : profile.cv &&
                                  profile.cv.parsed_cv &&
                                  profile.cv.parsed_cv.data &&
                                  profile.completeProfile.rate >= 50
                                ? 'Talent Already parsed the cv before'
                                : profile.cv &&
                                  profile.cv.parsed_cv &&
                                  profile.cv.parsed_cv.remaining_uploads == 0
                                ? `You parsed the cv more than 3 times, you can't parse the cv now`
                                : 'Please Upload CV to parse'
                            "
                          >
                            Parse CV
                          </span>
                          <div
                            class="form__error-label mb-2"
                            v-if="!profile.basicInfo.role"
                          >
                            (Please Add the Role First)
                          </div>

                          <button
                            @click="$refs.uploadCv.click()"
                            class="font-weight-bolder basic-info__reupload text-left"
                            :class="{ 'btn-disabled': isUploadingCv }"
                          >
                            <input
                              ref="uploadCv"
                              type="file"
                              class="d-none"
                              @change="uploadCv"
                              accept=".csv, .pdf, .doc, .docx, .txt"
                            />
                            <v-progress-circular
                              :size="20"
                              :width="2"
                              color="info"
                              indeterminate
                              v-if="isUploadingCv"
                              class="mr-3"
                            ></v-progress-circular>
                            <span v-if="profile.cv">Re-upload CV</span>
                            <span v-else>Upload CV</span>
                          </button>
                        </div>
                      </div>
                      <!--end::Item-->

                      <!--begin::Item-->
                      <div
                        class="d-flex align-items-center flex-lg-fill mr-5 mb-2"
                      >
                        <span class="mr-4">
                          <i
                            class="flaticon-file-2 display-2 text-muted font-weight-bold"
                          ></i>
                        </span>
                        <div class="d-flex flex-column flex-lg-fill">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-md"
                            >Qualifications</span
                          >
                          <router-link
                            :to="{
                              name: 'talent-qualifications',
                              params: { id: `${profileId}` },
                            }"
                            class="font-weight-bolder basic-info__view"
                            >View</router-link
                          >
                        </div>
                      </div>
                      <!--end::Item-->

                      <!--begin::Item-->
                      <div
                        class="d-flex align-items-center flex-lg-fill mr-5 mb-2"
                        v-if="profile.basicInfo.registerType == 2"
                      >
                        <span class="mr-4">
                          <i
                            class="flaticon-network display-2 text-muted font-weight-bold"
                          ></i>
                        </span>
                        <div class="d-flex flex-column flex-lg-fill">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-md"
                            >Workable</span
                          >
                          <router-link
                            :to="{
                              name: 'talent-timeline',
                              params: { id: `${profileId}` },
                            }"
                            class="font-weight-bolder basic-info__view"
                            >View</router-link
                          >
                        </div>
                      </div>
                      <!--end::Item-->

                      <!--begin::Item-->
                      <div
                        class="d-flex align-items-center flex-lg-fill mr-5 mb-2"
                        v-if="false"
                      >
                        <span class="mr-4">
                          <i
                            class="flaticon-profile display-2 text-muted font-weight-bold"
                          ></i>
                        </span>
                        <div class="d-flex flex-column">
                          <span
                            class="font-weight-bolder font-size-md text-dark-75"
                            >Sharable Link</span
                          >
                          <a
                            v-if="profile.basicInfo.allow_sharable_link"
                            class="font-weight-bolder basic-info__view"
                            target="_blank"
                            :href="`${talentProfileUrl}/${profile.basicInfo.sharable_code}`"
                            :disabled="!profile.basicInfo.allow_sharable_link"
                            >View</a
                          >
                          <span
                            v-else
                            class="font-weight-bolder text-muted cursor-pointer"
                            v-b-tooltip
                            title="Please complete profile first"
                          >
                            Unavailable
                          </span>
                        </div>
                      </div>
                      <!--end::Item-->
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div
                      class="pb-4 d-flex"
                      style="flex-direction: row-reverse"
                    >
                      <b-button
                        size="sm"
                        class="ml-5"
                        variant="outline-info"
                        @click="
                          $router.push({
                            name: 'edit-talent-profile',
                            params: { id: `${profile.basicInfo.id}` },
                          })
                        "
                        ><i class="fas fa-pen px-0 py-1"></i
                      ></b-button>

                      <div
                        v-b-tooltip.hover="
                          !profile.basicInfo.allow_sharable_link
                            ? 'Please complete profile first, then assign client'
                            : ''
                        "
                      >
                        <b-button
                          :variant="
                            !profile.basicInfo.allow_sharable_link ? '' : 'info'
                          "
                          :disabled="!profile.basicInfo.allow_sharable_link"
                          @click="showClientsModal(null)"
                          >Assign <i class="fas fa-user-tie px-0 py-1 ml-3"></i
                        ></b-button>
                      </div>
                      <div
                        v-b-tooltip.hover="
                          !profile.basicInfo.allow_sharable_link
                            ? 'Please complete profile first, then nominate client'
                            : ''
                        "
                      >
                        <b-button
                          :variant="
                            !profile.basicInfo.allow_sharable_link ? '' : 'info'
                          "
                          :disabled="!profile.basicInfo.allow_sharable_link"
                          @click="showNominateModal(null)"
                          class="mr-5"
                          >Nominate <i class="fas fa-users px-0 py-1 ml-3"></i
                        ></b-button>
                      </div>
                      <div
                        v-b-tooltip.hover="
                          !profile.basicInfo.allow_sharable_link
                            ? 'Please complete profile first, then nominate client'
                            : ''
                        "
                      >
                        <!--  <b-button
                          :variant="
                            !profile.basicInfo.allow_sharable_link ? '' : 'info'
                          "
                          :disabled="!profile.basicInfo.allow_sharable_link"
                          @click="showInquiryModal(null)"
                          class="mr-5"
                          >Inquiry <i class="fas fa-question px-0 py-1 ml-3"></i
                        ></b-button> -->
                      </div>
                    </div>
                    <!-- <AddClientToTalentWidget :profile="profile" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Content-->
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ACCOUNTS_TYPES from "@/constants/accounts-types";
import { getUrl } from "@/helpers";
import AddClientToTalentModal from "@/components/talent-profile/modals/AddClientToTalentModal.vue";
import NominateTalentToClientModal from "@/components/talent-profile/modals/NominateTalentToClientModal.vue";
import InquiryTalentModal from "@/components/talent-profile/modals/InquiryTalentModal.vue";

/* import AddClientToTalentWidget from "@/components/talent-profile/AddClientToTalentWidget.vue";
 */
import Swal from "sweetalert2";

export default {
  name: "BasicInfoCard",
  props: {
    profile: {
      required: true,
      type: Object,
    },
    profileId: {
      required: true,
      type: String,
    },
  },
  components: {
    AddClientToTalentModal,
    NominateTalentToClientModal,
    InquiryTalentModal,
    /* AddClientToTalentWidget, */
  },
  created() {
    this.getMarketingCampaignList({ type: 1 });
  },
  data() {
    return {
      ACCOUNTS_TYPES,
      default_photo: process.env.BASE_URL + "media/users/blank.png",
      awsBaseUrl: process.env.VUE_APP_AWS_BASE_URL + "/",
      // upload image
      maxImageSize: 1024 * 1,
      uploadedImage: null,
      acceptedImageTypes: ["image/jpeg", "png", "image/png", "image/svg+xml"],
      isUploadingImage: false,
      // upload cv
      maxCvSize: 1024 * 5,
      acceptedCvTypes: [
        "text/plain",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/pdf",
        "text/csv",
      ],
      isUploadingCv: false,
      talentProfileUrl: process.env.VUE_APP_TALENT_PROFILE_URL,
      activeClient: null,
      activeNominate: null,
    };
  },
  computed: {
    ...mapGetters({
      marketingCampaignList: "shared/getMarketingCampaignList",
    }),
    photo() {
      let imagePath = this.profile?.basicInfo?.image;
      return this.uploadedImage || imagePath || this.default_photo;
    },
    getCampaign() {
      return this.marketingCampaignList.find(
        (element) => element.id === this.profile.basicInfo.marketing_campaign
      )?.name;
    },
  },
  methods: {
    ...mapActions({
      uploadTalentImage: "talentProfile/uploadTalentImage",
      uploadTalentCv: "talentProfile/uploadTalentCv",
      deleteClientFromTalent: "talentProfile/deleteClientFromTalent",
      getMarketingCampaignList: "shared/getMarketingCampaignList",
    }),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getRandomValueFromArray() {
      const colors = [
        "Primary",
        "Secondary",
        "Success",
        "Danger",
        "Warning",
        "Info",
        "Light",
        "Dark",
      ];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex].toLowerCase();
    },
    jobTypeColor(type) {
      if (type === "remote") {
        return "primary";
      } else if (type === "onsite-accommodation") {
        return "success";
      } else if (type === "onsite") {
        return "info";
      } else if (type === "onsite-business-visa") {
        return "secondary";
      } else {
        return "warning";
      }
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: "Remote",
          value: "remote",
        },
        {
          text: "Onsite",
          value: "onsite",
        },
        {
          text: "Onsite - Iqama",
          value: "onsite-accommodation",
        },
        {
          text: "Onsite - Business Visa",
          value: "onsite-business-visa",
        },
        {
          text: "Hybrid",
          value: "hybrid",
        },
      ];

      return jobs.find((j) => j.value == type).text;
    },
    uniqueArray(arr) {
      return Array.from(new Set(arr.map((obj) => obj.id))).map((id) =>
        arr.find((obj) => obj.id === id)
      );
    },
    showClientsModal(contract) {
      if (contract) {
        this.activeClient = JSON.parse(JSON.stringify(contract));
      } else {
        this.activeClient = null;
      }
      this.$refs["clients-modal"].click();
    },
    showNominateModal(nominate) {
      if (nominate) {
        this.activeNominate = JSON.parse(JSON.stringify(nominate));
      } else {
        this.activeNominate = null;
      }
      this.$refs["nominate-modal"].click();
    },
    showInquiryModal(inquiry) {
      inquiry;
      this.$refs["inquiry-modal"].click();
    },
    confirmDeleteClientFromUser(client_id, contract_id) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            client_id,
            talent_id: this.$route.params.id,
            contract_id,
          };
          this.deleteClientFromTalent(payload);
        }
      });
    },
    uploadImage(e) {
      const file = e.target.files[0];
      if (!this.acceptedImageTypes.includes(file.type)) {
        this.$toasted.show("Allowed Extensions are .jpg , .jpeg , .png .svg", {
          type: "error",
          icon: "error",
        });
        return;
      }

      if (file.size / 1024 > this.maxImageSize) {
        this.$toasted.show("The Image Size Must be Less Than Or Equal 1 MB", {
          type: "error",
          icon: "error",
        });
        return;
      }

      let formData = new FormData();
      formData.append("image", file);

      this.isUploadingImage = true;
      this.uploadTalentImage({ id: this.profileId, data: formData })
        .then(() => {
          const reader = new FileReader();

          reader.onload = (event) => {
            this.uploadedImage = event.target.result;
          };

          reader.readAsDataURL(file);
        })
        .finally(() => {
          this.isUploadingImage = false;
        });
    },
    uploadCv(e) {
      const file = e.target.files[0];
      if (!this.acceptedCvTypes.includes(file.type)) {
        this.$toasted.show(
          "Allowed Extensions are .csv, .pdf, .doc, .docx and .txt",
          {
            type: "error",
            icon: "error",
          }
        );
        return;
      }

      if (file.size / 1024 > this.maxCvSize) {
        this.$toasted.show("The Image Size Must be Less Than Or Equal 5 MB", {
          type: "error",
          icon: "error",
        });
        return;
      }

      let formData = new FormData();
      formData.append("cv", file);

      if (
        this.profile.completeProfile.rate > 50 ||
        (this.profile.cv && !this.profile.cv.remaining_uploads)
      ) {
        formData.append("parse", 0);
        this.isUploadingCv = true;
        this.uploadTalentCv({ id: this.profileId, data: formData }).finally(
          () => {
            this.isUploadingCv = false;
          }
        );
      } else if (this.profile.cv?.remaining_uploads) {
        Swal.fire({
          title: "Do you want parse this Cv?",
          text: `You have (${this.profile.cv.remaining_uploads}) Times remaining to upload your resume with parsing feature`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#6f56c5",
          cancelButtonColor: "#E4E6EF",
          cancelButtonText: "No",
          customClass: {
            cancelButton: "text-dark",
            icon: "text-danger border-danger",
          },
          confirmButtonText: "Yes, parse it!",
        }).then((result) => {
          if (result.isConfirmed) {
            formData.append("parse", 1);
            this.isUploadingCv = true;
            this.uploadTalentCv({ id: this.profileId, data: formData }).finally(
              () => {
                this.isUploadingCv = false;
              }
            );
          } else {
            formData.append("parse", 0);
            this.isUploadingCv = true;
            this.uploadTalentCv({ id: this.profileId, data: formData }).finally(
              () => {
                this.isUploadingCv = false;
              }
            );
          }
        });
      } else {
        formData.append("parse", 1);
        this.isUploadingCv = true;
        this.uploadTalentCv({ id: this.profileId, data: formData }).finally(
          () => {
            this.isUploadingCv = false;
          }
        );
      }
    },
    openAddClinetToTalentModal() {
      this.$refs["add-client-to-talent-modal"].click();
    },
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.profile-avatar {
  .btn-icon {
    right: 0;
    top: 0;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.cv-name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 300px;
  word-break: break-word;
}

.basic-info__title {
  font-size: 1.2rem;
}

.basic-info__view {
  font-size: 1.2rem;
  color: var(--v-anchor-base);
}

.basic-info__reupload {
  font-size: 1.1rem;
  color: var(--v-success-base);
}

.card-stretch {
  height: calc(100% - 25px) !important;
  min-height: calc(100% - 25px) !important;
}
</style>
